<template>
  <v-list nav>
    <v-list-item-group color="primary">
      <template v-for="(menu, index) in menus">
        <v-list-item
          v-if="permiteRole(menu.roles) && !menu.children"
          :key="index"
          :to="menu.children ? null : { path: menu.path }"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="!!menu.children">
            <v-icon>{{ "mdi-chevron-down" }}</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-group :key="index + 'menu'" v-if="!!menu.children">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(item, indexItem) in menu.children">
            <v-list-item
              v-if="permiteRole(item.roles)"
              :key="index + '-' + indexItem"
              :to="{ path: menu.path + item.path }"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MenuDashboard",

  data() {
    return {};
  },

  computed: {
    ...mapState("Usuario", {
      role: (state) => state.role,
    }),

    menus() {
      return [
        {
          name: "Home",
          path: "/",
          icon: "mdi-home-outline",
          roles: ["ADMIN"],
        },
        {
          name: "Usuarios",
          path: "/usuarios",
          icon: "mdi-account-group-outline",
          roles: ["ADMIN"],
        },
        {
          name: "Palestras",
          path: "/palestras",
          icon: "mdi-presentation",
          roles: ["ADMIN"],
        },
        {
          name: "Avaliações de Palestras",
          path: "/palestras-avaliacoes",
          icon: "mdi-star",
          roles: ["ADMIN"],
        },
        {
          name: "Notificações",
          path: "/notificacoes",
          icon: "mdi-bell-outline",
          roles: ["ADMIN"],
        },
        {
          name: "Networking",
          path: "/networkings",
          icon: "mdi-wan",
          roles: ["ADMIN"],
        },
        {
          name: "Chat",
          path: "/chat",
          icon: "mdi-chat-outline",
          roles: ["ADMIN"],
        },
        {
          name: "Eduzz",
          path: "/eduzz",
          icon: "mdi-currency-usd",
          roles: ["ADMIN"],
        },
        {
          name: "Leads",
          path: "/leads",
          icon: "mdi-account-group-outline",
          roles: ["STAFF"],
        },
        {
          name: "Relatórios",
          path: "/relatorios",
          icon: "mdi-file-chart-outline",
          roles: ["ADMIN", "STAFF"],
        },
        {
          name: "Tags Groups",
          path: "/tags-groups",
          icon: "mdi-tag-multiple",
          roles: ["ADMIN"],
        },
        {
          name: "Loja",
          path: "/loja",
          icon: "mdi-storefront-outline",
          roles: ["ADMIN"],
          redirect: "/loja/produtos",
          children: [
            {
              name: "Produtos",
              path: "/produtos",
              icon: "mdi-archive-outline",
              roles: ["ADMIN", "MERCADO"],
            },
            {
              name: "Categorias",
              path: "/categorias",
              icon: "mdi-palette-swatch-outline",
              roles: ["ADMIN", "MERCADO"],
            },
            {
              name: "Pedidos",
              path: "/pedidos",
              icon: "mdi-list-box-outline",
              roles: ["ADMIN", "MERCADO"],
            },
            {
              name: "Relatórios",
              path: "/relatorios",
              icon: "mdi-file-outline",
              roles: ["ADMIN"],
            },
          ],
        },
      ];
    },
  },

  methods: {
    permiteRole(roles) {
      return roles.some((item) => item === this.role);
    },
  },
};
</script>

<style></style>
